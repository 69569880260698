import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import ImagesCarousel from '../ImagesCarousel'
import ReactPlayer from 'react-player'

const divStyle = {
  margin: 0 + ' auto',
  maxWidth: 640 + 'px',
  color: 'red'
}

const HomePageTemplate = ({
  history,
  screenWidth,
  screenHeight,
  pf_id,
  img_id,
  showModal,
  title,
  heading,
  description,
  offerings,
  gridInfo,
  meta_title,
  meta_description,
  testimonials,
  grid,
  lang,
}) => (
  <div>
    <Helmet>
      <title>{meta_title} </title>
      <meta name='description' content={meta_description} />
    </Helmet>
    {/* <section className='hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h1 className='title'>
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <section>
      <div className='firstElem img_carousel'>

        <ImagesCarousel data={grid} autoPlay={true} />
      </div>
    </section>

    <section className='section section--gradient'>
      <div className='-container'>

        <div className='section'>
          <div className='columns'>
            <div className='column is-12 is-offset-0'>
              <div className='content'>
                <div>
                  <h3 className='has-text-weight-light is-size-3 has-text-centered'>
                    {heading}
                  </h3>
                  <p className='has-text-centered'>{description}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='has-text-centered videoSection'>
      <ReactPlayer url='/video/Video_Kalzinator.mp4'
      style={divStyle}
      controls
      width={100 + '%'}
      height={'auto'}
      />
    {/*
      <svg id="circle_1" xmlns="http://www.w3.org/2000/svg" viewBox="20 20 900 900">
  <defs>
    <marker id="arrowhead" markerWidth="30" markerHeight="7"
    refX="5" refY="3.5" orient="auto">
      <polygon points="0 0, 10 3.5, 0 7" />
    </marker>
  </defs>
  <line x1="0" y1="0" x2="100" y2="100"
	stroke="#000" strokeWidth="8"
	markerEnd="url(#arrowhead)" />
</svg> */}
    </section>

  </div>
)

HomePageTemplate.propTypes = {
  grid: PropTypes.object,
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),

  testimonials: PropTypes.array,

}

export default HomePageTemplate
