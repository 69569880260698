/**
 * Created by vaibhav on 31/3/18
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './style.css'
import Img from 'gatsby-image'
import { Carousel } from 'react-responsive-carousel'
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'
import Fade from 'react-reveal/Fade'
import windowSize from 'react-window-size'

const breakpoint = {
  mobile: 727,
}

export default class ImagesCarousel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      _height: 0,
      screenWidth: '',
      screenHeight: '',
      _currentIndex: 0,
    }

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this)
  }

  getImgData (index, size, title, subheadline, slug) {
    return (
      <div key={'-' + index}>
        <Img style={{'display': 'block'}} alt={title} title={title} sizes={size} />

        <div className='legend has-text-centered has-text-weight-light'>
          {title} <br />
          {subheadline}
        </div>

      </div>
    )
  }

  handleWindowSizeChange = () => {
    this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight })
  };

  componentDidMount () {
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.handleWindowSizeChange()
  }

  render () {
    var showArrows = !(this.state.screenWidth < breakpoint.mobile)
    var data = this.props.data

    const ar1 = data.carouselPicts.edges
    const ar2 = data.markdownRemark.frontmatter.carousel

    return (

      <Carousel
        showIndicators={false}
        showArrows={showArrows}
        className='topSlider'
        showThumbs={false}
        /* centerMode={true}
            centerSlidePercentage={100} */
        emulateTouch
        showStatus={false}
        autoPlay={this.props.autoPlay}
        stopOnHover
        infiniteLoop
        useKeyboardArrows
        interval={2000}
        transitionTime={300}
        onClick={(evt) => evt.stopPropagation()}
        onChange={(i) => this.setState({ _currentIndex: i })}
        selectedItem={this.state._currentIndex}
      >
        {ar2.map(({image}, index) => (

          this.getImgData(
            index,
            // we use this src when pulled from frontmatter without using imagesharp (just a string)
            // image[0].src,
            ar1[index].node.childImageSharp.fluid, // from imagesharp
            image[0].title,
            image[0].sub_headline,
            image[0].slug
          )

        ))}
      </Carousel>
    )
  }
}
ImagesCarousel.propTypes = {
  data: PropTypes.object.isRequired,
  autoPlay: PropTypes.bool.isRequired,
}
