import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import HomePageTemplate from '../components/HomePageTemplate'

const HomePage = ({data}) => {
  const {frontmatter} = data.markdownRemark

  return (
    <div>
    <Helmet>
      <title>{frontmatter.meta_title}</title>
      <meta name='description' content={frontmatter.meta_description} />
      <body className={frontmatter.slug} />
    </Helmet>
    <HomePageTemplate
      title={frontmatter.title}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      heading={frontmatter.heading}
      description={frontmatter.description}
      offerings={frontmatter.offerings}
      testimonials={frontmatter.testimonials}
      gridInfo={frontmatter.grid1}
      grid={data}
    />
    </div>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
        slug
        description
        carousel {
          image{
            src
            title
            sub_headline
            slug
          }
        }
        offerings {
          blurbs {
            image
            text
          }
        }
        grid1 {
          image{
            src
            title
            sub_headline
            slug
            text
          }
        }
        testimonials {
          author
          quote
        }
      }
    }

    gridPicts1: allFile(
      filter: { relativeDirectory: { regex: "/home-grid/" } },
      sort: { order: ASC, fields: [name]}
    ) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxWidth: 1344, quality: 75) {
          ...GatsbyImageSharpFluid
        }
          }
        }
      }
    }
    carouselPicts: allFile(
      filter: { relativeDirectory: { regex: "/home-carousel/" } },
      sort: { order: ASC, fields: [name]}
    ) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxWidth: 1344, quality: 75) {
          ...GatsbyImageSharpFluid
        }
          }
        }
      }
    }
  }
`
